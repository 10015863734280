import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import ApiPageSpecificFaq from "@/api/ApiPageSpecificFaq";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import LeftMenuTab from "@/components/LeftMenu/LeftMenuTab.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import { Getter } from "vuex-class";
import EUserEnum from "@/struct/user/EUserEnum";
import i18n from "@/i18n";
let FaqEdit = class FaqEdit extends Vue {
    constructor() {
        super(...arguments);
        this.TableHeaders = [
            {
                text: i18n.t("table_headers.sorting"),
                value: "priority",
                width: "10%",
            },
            {
                text: i18n.t("table_headers.question"),
                value: "question",
                width: "40%",
            },
            {
                text: "URL",
                value: "url",
                width: "30%",
            },
            {
                text: i18n.t("table_headers.action"),
                value: "action",
            },
        ];
        this.Breadcrumbs = [
            {
                to: "/",
                text: "Home",
            },
            {
                to: "/page-specific-faq",
                exact: true,
                text: "Page specific FAQ",
            },
            {
                text: "Questions",
                disabled: true,
            },
        ];
        this.TableItems = [];
    }
    async ChangeFaqPublish(item) {
        try {
            const response = await ApiPageSpecificFaq.UpdateFaqPublish(item.publish ? 1 : 0, ApiEnter.CurrentSessionUUID, item.uuid);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.GetFaq();
                });
            }
            else {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
    }
    DeleteFaq(faq_uuid) {
        sweetalert({
            title: this.$t("general.confirm"),
            text: this.$t("files.accept_delete"),
            icon: "warning",
            buttons: [this.$t("general.no"), this.$t("general.yes")],
        }).then(async (isConfirm) => {
            if (isConfirm == true) {
                const response = await ApiPageSpecificFaq.DeleteFaqQuestion(ApiEnter.CurrentSessionUUID, faq_uuid);
                if (typeof response == "boolean") {
                    sweetalert({
                        title: this.$t("general.success"),
                        text: this.$t("general.success_deleted"),
                        icon: "success",
                    });
                    this.GetFaq();
                }
                else {
                    sweetalert({
                        title: this.$t("general.error"),
                        text: this.$t("general.request_error"),
                        icon: "info",
                    });
                }
            }
        });
    }
    CanAdminAccess() {
        return this.GetCurrentSessionUser != undefined && this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.SuperAdmin;
    }
    async GetFaq() {
        try {
            this.TableItems = await ApiPageSpecificFaq.GetFaq(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
    }
    mounted() {
        this.GetFaq();
    }
};
__decorate([
    Getter("getCurrentSessionUser")
], FaqEdit.prototype, "GetCurrentSessionUser", void 0);
FaqEdit = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, LeftMenuTab },
    })
], FaqEdit);
export default FaqEdit;
