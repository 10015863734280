import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import ApiPageSpecificFaq from "@/api/ApiPageSpecificFaq";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditorConfig from "@/data/CKeditor/config";
let CreateQuestion = class CreateQuestion extends Vue {
    constructor() {
        super(...arguments);
        this.editor = ClassicEditor;
        this.editorConfig = CKEditorConfig.editorConfig(ApiEnter.CurrentSessionUUID);
        this.Breadcrumbs = [
            {
                to: "/",
                text: "Home",
            },
            {
                to: "/page-specific-faq",
                exact: true,
                text: "Page specific FAQ",
            },
            {
                text: "Questions",
                disabled: true,
            },
        ];
        this.newPost = {
            answer: "",
            question: "",
            publish: true,
            priority: "",
            url: "",
        };
        this.HasLoading = false;
    }
    async OnClickSubmit() {
        this.HasLoading = true;
        if (ApiEnter.CurrentSessionUUID != undefined) {
            this.$forceUpdate();
            const blog_uuid = await ApiPageSpecificFaq.CreateFaqQuestion(ApiEnter.CurrentSessionUUID, this.newPost.answer, this.newPost.question, this.newPost.priority, this.newPost.publish ? 1 : 0, this.newPost.url);
            if (blog_uuid == undefined || blog_uuid.length != 36) {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
                return;
            }
            sweetalert({
                title: this.$t("general.success"),
                text: this.$t("general.success_created"),
                icon: "success",
            }).then(() => {
                this.$forceUpdate();
                this.newPost.answer = "";
                this.newPost.question = "";
                this.newPost.priority = "";
                this.newPost.publish = true;
                this.newPost.url = "";
                this.$router.push(`/page-specific-faq`);
            });
        }
        this.HasLoading = false;
    }
};
CreateQuestion = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, CKEditor },
    })
], CreateQuestion);
export default CreateQuestion;
