import axios from "axios";
class ApiPageSpecificFaq {
    static async GetFaq(session_uuid) {
        try {
            const result = await axios.get("/api/admin/get-page-specific-faq", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get faq");
        }
    }
    static async GetFaqByUUID(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/get-page-specific-faq-by", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return undefined;
        }
    }
    static async CreateFaqQuestion(session_uuid, answer, question, priority, publish, url) {
        try {
            const result = await axios.post("/api/admin/create-page-specific-faq", {
                answer: answer,
                question: question,
                priority: priority,
                publish: publish,
                url: url,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e && "data" in e.response && "response" in e.response.data) {
                return e.response.data.response;
            }
        }
        return undefined;
    }
    static async DeleteFaqQuestion(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/delete-page-specific-faq", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async UpdateFaqPublish(publish, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/change-page-specific-faq-publish", {
                uuid: uuid,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async UpdateFaqInfo(answer, question, priority, publish, url, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/change-page-specific-faq", {
                uuid: uuid,
                answer: answer,
                question: question,
                priority: priority,
                publish: publish,
                url: url,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
}
export default ApiPageSpecificFaq;
