import FaqQuestions from "@/views/PageSpecificFaq/FaqQuestions.vue";
import CreateQuestion from "@/views/PageSpecificFaq/CreateQuestion.vue";
import EditQuestion from "@/views/PageSpecificFaq/EditQuestion.vue";
const routes = [
    {
        path: "/page-specific-faq",
        name: "FaqQuestions",
        component: FaqQuestions,
    },
    {
        path: "/page-specific-faq/create",
        name: "CreateQuestion",
        component: CreateQuestion,
    },
    {
        path: "/page-specific-faq/edit/:faq_uuid",
        name: "EditQuestion",
        component: EditQuestion,
    }
];
export default routes;
